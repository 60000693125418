'use client'
import React from 'react'

import FourOhFour from '../../modules/fourOhFour'

const FourOhFourPage = ({}) => {
  return <FourOhFour />
}

export default FourOhFourPage
