import React from 'react'
import styled from 'styled-components'
import image from './fourOhFour.png'
import TypographyElement from '../../elements/typography'
import ButtonElement from '../../elements/buttons'
import { ImageBuilderFrontend } from '../../elements/imageBuilder'

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: -24px;
  display: flex;
  @media screen and (max-width: 1200px) {
    display: block;
  }
  .imageContainer,
  .content {
    vertical-align: middle;
    width: 50%;

    @media screen and (max-width: 1200px) {
      display: block;
      width: 100%;

      > span {
        display: block !important;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 143px;
    .button {
      padding-top: 50px;
    }

    @media screen and (max-width: 1200px) {
      padding: 60px 60px;
    }

    @media screen and (max-width: 550px) {
      padding: 60px 30px;
    }
  }
  .imageContainer {
    position: relative;
    > img {
      height: unset !important;
      position: relative !important;
    }
    .text {
      position: absolute;
      top: 50%;
      left: 50%;
      > * {
        transform: translate(-50%, -50%);
      }
    }
  }
`

const FourOhFour = () => {
  return (
    <Wrapper>
      <div className="imageContainer">
        <ImageBuilderFrontend
          image={image}
          // width={945}
          // height={941}
        />
        <div className="text">
          <TypographyElement variant="fourOhFour" component="h2">
            404
          </TypographyElement>
        </div>
      </div>
      <div className="content">
        <TypographyElement variant="title">
          Pagina niet gevonden
        </TypographyElement>
        <TypographyElement variant="p">
          Deze pagina kon helaas niet gevonden worden.
        </TypographyElement>
        <div className="button">
          <ButtonElement variant="return" href="/">
            Terug naar de homepage
          </ButtonElement>
        </div>
      </div>
    </Wrapper>
  )
}

export default FourOhFour
