'use client'

import { useEffect } from 'react'

const ThrowError = () => {
  useEffect(() => {
    if (window?.dataLayer) {
      window.dataLayer.push({
        event: '404',
        404: window?.location?.href,
      })
    }
  }, [])

  return <></>
}

export default ThrowError
