'use client'
import React from 'react'
import FourOhFourPage from '@/core/pagetemplates/404'

import PageWrapper from '@/_layout/pageWrapper'
import ThrowError from '@/core/error'

const FourOhFour = ({ error }) => {

  return (
    <PageWrapper pageClass="404">
      <FourOhFourPage />
      <ThrowError />
    </PageWrapper>
  )
}

export default FourOhFour
